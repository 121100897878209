var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('orders.salesOrders'),"description":_vm.$t('orders.fromHereYouCanControlSalesOrders')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('orders.searchForAsalesOrder'),"emptyTableText":_vm.$t('orders.thereAreNoOrders'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.orders_add,"statusSearch":true,"selectAll":true,"statusRole":_vm.$user.role.orders_allow_status,"deleteAllRole":_vm.$user.role.orders_delete,"statusValues":[
                    { title: _vm.$t('orders.situation'), value: ''},
                    { title: _vm.$t('orders.all'), value: ''},
                    { title: _vm.$t('orders.draft'), value: 0 },
                    { title: _vm.$t('orders.call1'), value: 1 },
                    { title: _vm.$t('orders.call2'), value: 2 },
                    { title: _vm.$t('orders.call3'), value: 3 },
                    { title: _vm.$t('orders.Certain'), value: 4 },
                    { title: _vm.$t('orders.readyForShipping'), value: 5 },
                    { title: _vm.$t('orders.shipped'), value: 6 },
                    { title: _vm.$t('orders.returnd'), value: 7 },
                    { title: _vm.$t('orders.returnedToOrigen'), value: 8 },
                    { title: _vm.$t('orders.rejected'), value: 9 },
                    { title: _vm.$t('orders.canceled'), value: 10 } ],"cloumns":[
                    {column: 'code',    title: _vm.$t('orders.salesOrder') ,type: 'mainLink' , sort: true },
                    {column: 'date',    title: _vm.$t('orders.orderDate') ,type: 'text' , sort: true },
                    {column: 'client_type', title: _vm.$t('orders.customerName'),type: 'clintLink',sort: false,},
                    {column: 'total',    title: _vm.$t('orders.cost') ,type: 'text' , sort: true },
                    {column: 'status',    title: _vm.$t('orders.situation') ,type: 'status' , sort: true ,  values: [
                      { title: _vm.$t('orders.draft'), value: 0, color: 'dark'},
                      { title: _vm.$t('orders.call1'), value: 1, color: 'dark' },
                      { title: _vm.$t('orders.call2'), value: 2, color: 'dark' },
                      { title: _vm.$t('orders.call3'), value: 3, color: 'dark' },
                      { title: _vm.$t('orders.Certain'), value: 4, color: 'success' },
                      { title: _vm.$t('orders.readyForShipping'), value: 5, color: 'success' },
                      { title: _vm.$t('orders.shipped'), value: 6, color: 'success' },
                      { title: _vm.$t('orders.returnd'), value: 7, color: 'danger'},
                      { title: _vm.$t('orders.returnedToOrigen'), value: 8 , color: 'danger'},
                      { title: _vm.$t('orders.rejected'), value: 9, color: 'danger' },
                      { title: _vm.$t('orders.canceled'), value: 10, color: 'danger' } ]},
                    {column: 'options', title: _vm.$t('orders.settings'), type: 'options', options: [
                        {name: 'show'},
                        {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.orders_edit},
                        {name: 'file'},
                        {name: 'stat',   role: _vm.$user.admin || _vm.$user.role.orders_edit},
                        {name: 'outLink',   role: true},
                        {name: 'printOrder',   role: true},
                        {name: 'download',   role: true},
                        {name: 'delete', role: _vm.$user.admin || _vm.$user.role.orders_delete} ]}
                  ],"deleteText":{
                    attention: _vm.$t('allerts.Attention'),
                    areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                    close: _vm.$t('allerts.close'),
                    confirm: _vm.$t('allerts.confirm'),
                    loading: _vm.$t('allerts.loading'),
                  }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }