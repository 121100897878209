<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('orders.salesOrders')"
                     :description="$t('orders.fromHereYouCanControlSalesOrders')" />
        <indexTable :searchText="$t('orders.searchForAsalesOrder')"
                    :emptyTableText="$t('orders.thereAreNoOrders')"
                    :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                    :buttonRole="$user.role.orders_add"
                    :statusSearch="true"
                    :selectAll="true"
                    :statusRole="$user.role.orders_allow_status"
                    :deleteAllRole="$user.role.orders_delete"
                    :statusValues="[
                      { title: $t('orders.situation'), value: ''},
                      { title: $t('orders.all'), value: ''},
                      { title: $t('orders.draft'), value: 0 },
                      { title: $t('orders.call1'), value: 1 },
                      { title: $t('orders.call2'), value: 2 },
                      { title: $t('orders.call3'), value: 3 },
                      { title: $t('orders.Certain'), value: 4 },
                      { title: $t('orders.readyForShipping'), value: 5 },
                      { title: $t('orders.shipped'), value: 6 },
                      { title: $t('orders.returnd'), value: 7 },
                      { title: $t('orders.returnedToOrigen'), value: 8 },
                      { title: $t('orders.rejected'), value: 9 },
                      { title: $t('orders.canceled'), value: 10 },
                    ]"
                    :cloumns="[
                      {column: 'code',    title: $t('orders.salesOrder') ,type: 'mainLink' , sort: true },
                      {column: 'date',    title: $t('orders.orderDate') ,type: 'text' , sort: true },
                      {column: 'client_type', title: $t('orders.customerName'),type: 'clintLink',sort: false,},
                      {column: 'total',    title: $t('orders.cost') ,type: 'text' , sort: true },
                      {column: 'status',    title: $t('orders.situation') ,type: 'status' , sort: true ,  values: [
                        { title: $t('orders.draft'), value: 0, color: 'dark'},
                        { title: $t('orders.call1'), value: 1, color: 'dark' },
                        { title: $t('orders.call2'), value: 2, color: 'dark' },
                        { title: $t('orders.call3'), value: 3, color: 'dark' },
                        { title: $t('orders.Certain'), value: 4, color: 'success' },
                        { title: $t('orders.readyForShipping'), value: 5, color: 'success' },
                        { title: $t('orders.shipped'), value: 6, color: 'success' },
                        { title: $t('orders.returnd'), value: 7, color: 'danger'},
                        { title: $t('orders.returnedToOrigen'), value: 8 , color: 'danger'},
                        { title: $t('orders.rejected'), value: 9, color: 'danger' },
                        { title: $t('orders.canceled'), value: 10, color: 'danger' },
                      ]},
                      {column: 'options', title: $t('orders.settings'), type: 'options', options: [
                          {name: 'show'},
                          {name: 'edit',   role: $user.admin || $user.role.orders_edit},
                          {name: 'file'},
                          {name: 'stat',   role: $user.admin || $user.role.orders_edit},
                          {name: 'outLink',   role: true},
                          {name: 'printOrder',   role: true},
                          {name: 'download',   role: true},
                          {name: 'delete', role: $user.admin || $user.role.orders_delete},
                      ]}
                    ]"
                    :deleteText="{
                      attention: $t('allerts.Attention'),
                      areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                      close: $t('allerts.close'),
                      confirm: $t('allerts.confirm'),
                      loading: $t('allerts.loading'),
                    }" />
      </div>
    </div>
  </div>

</template>
<script>

  import axios from 'axios'

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/orders'
      }
    },
    components: {
      indexHeader,
      indexTable
    }
  }

</script>
